import React from 'react';

export default function Contact() {
	return (
		<section>
			<h2>Want to contact me?</h2>
			{/* add form here */}
			<form>form</form>
			<p>Or you can reach me via email or through LinkedIn.</p>
			<p>Email: andrewdavid.reed@gmail.com</p>
			<p>LinkedIn</p>
		</section>
	);
}
